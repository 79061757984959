
export const Environment ={
   /*BENESHTY*/
/*     baseURL:'https://docklanddashboardapi.geniussystemapi.com',
 */     baseURL:'https://www.beneshtydashboardapi.geniussystemapi.com', 
    baseURL2:'https://adminapi.beneshty.com',     
    /*kOKYsTORE*/
  /*   baseURL:'https://kokystoreapi.geniussystemapi.com',
    baseURL2:'https://admin.kokyandgody.com'  */   
    /*bEBO*/ 
      /*   baseURL:'https://beboapi.geniussystemapi.com',
    baseURL2:'https://www.adminapi.bebo-eg.com'  */  
 }

export default Environment