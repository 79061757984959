import React,{useState,useEffect} from 'react'
import styles from '../../Styles/elma8zon.module.css'
import Sidebar from '../../Layout/Sidebar'
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import Environment from "../../config";
import TableStokeTwo from './TableStokeTwo';
import Form from 'react-bootstrap/Form';

import {
    format
  } from "date-fns";
const StokeTwo = () => {
/*   const { token } = useSelector((state) => state.user);
  const[InventoryData,setInventoryData]=useState()
  const[data,setData]=useState([])
  const[load,setLoad]=useState(true)
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [selectedOption, setSelectedOption] = useState("lastYear");
  useEffect(() => {
    const today = new Date();
    let from, to;

    switch (selectedOption) {
      case "currentDay":
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastMonth":
        from = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastQuarter":
        from = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastYear":
        from = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "custom":
        from = "";
        to = "";
        break;
      default:
        break;
    }

    setDateFrom(from ? format(from, "yyyy-MM-dd") : "");
    setDateTo(to ? format(to, "yyyy-MM-dd") : "");
  }, [selectedOption]); // Depend on selectedOption to update dates when it changes

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
  };

  const handleDateFromChange = (event) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event) => {
    setDateTo(event.target.value);
  };

  useEffect(() => {
    if(dateFrom&& dateTo){
    axios
      .get(`${Environment.baseURL}/api/Inventory?from=${dateFrom}&to=${dateTo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setInventoryData(response.data)
        setLoad(false)
     
      })
      .catch((err) => {
        console.log(err.response);
      });

      axios
      .get(`${Environment.baseURL}/api/Inventory/getInventoryDataByBranches?from=${dateFrom}&to=${dateTo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data)
        setLoad(false)
     
      })
      .catch((err) => {
        console.log(err.response);
      });
      setLoad(true)
    }
  }, [selectedOption,dateFrom , dateTo]);
   */
  const { token } = useSelector((state) => state.user);
  const [InventoryData, setInventoryData] = useState([]);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [selectedOption, setSelectedOption] = useState("lastYear");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    const today = new Date();
    let from, to;

    switch (selectedOption) {
      case "currentDay":
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastMonth":
        from = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastQuarter":
        from = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastYear":
        from = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "custom":
        from = "";
        to = "";
        break;
      default:
        break;
    }

    setDateFrom(from ? format(from, "yyyy-MM-dd") : "");
    setDateTo(to ? format(to, "yyyy-MM-dd") : "");
  }, [selectedOption]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDateFromChange = (event) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event) => {
    setDateTo(event.target.value);
  };

  useEffect(() => {
    if (debounceTimeout) clearTimeout(debounceTimeout);

    const timeout = setTimeout(() => {
      if (dateFrom && dateTo) {
        axios
        .get(`${Environment.baseURL}/api/Inventory?from=${dateFrom}&to=${dateTo}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setInventoryData(response.data)
         
       
        })
        .catch((err) => {
          console.log(err.response);
        });
  
        axios
        .get(`${Environment.baseURL}/api/Inventory/getInventoryDataByBranches?from=${dateFrom}&to=${dateTo}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setData(response.data)
          setLoad(false)
       
        })
        .catch((err) => {
          console.log(err.response);
        });
        setLoad(true)

      }
    }, 500); // Adjust the delay as needed

    setDebounceTimeout(timeout);

    return () => clearTimeout(timeout);
  }, [dateFrom, dateTo]);

/*   const fetchInventoryData = async () => {
    setLoad(true);
    try {
      const inventoryResponse = await axios.get(
        `${Environment.baseURL}/api/Inventory?from=${dateFrom}&to=${dateTo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInventoryData(inventoryResponse);
      
      const branchesResponse = await axios.get(
        `${Environment.baseURL}/api/Inventory/getInventoryDataByBranches?from=${dateFrom}&to=${dateTo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(branchesResponse);
    } catch (err) {
      console.error('Error fetching data:', err.response);
    } finally {
      setLoad(false);
    }
  }; */
  return (
    <>
  <section className={`${styles.home}`}>
        <Sidebar active="ma8zoon" />
        <div className={`${styles.homeContainer}`}>
          <h2 className={`${styles.h2} m-4`}> حركة المخزون </h2>
          <Container>
         
          {
            !load?
            <div className={`${styles.block}`}>
            <Row className={`${styles.dash}`}>
              <Col>
              <Row className="mt-5 mb-4" dir="rtl">
                  <Col>
                    <label>اختر التاريخ</label>
                    <Form.Select
                      aria-label="Default select example"
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
           <option value="currentDay">اليوم الحالي</option>
                      <option value="lastMonth">اخر شهر</option>
                      <option value="lastQuarter">اخر ثلاثة اشهر</option>
                      <option value="lastYear">اخر سنة</option>
                      <option value="custom">اختر تاريخ محدد</option>
                    </Form.Select>
                  </Col>
                  <Col>
                    {selectedOption === "custom" && (
                      <div className={`${styles.col__date}`}>
                        <div>
                        <label>من تاريخ </label>
                          <Form.Control
                            type="date"
                            value={dateFrom}
                            onChange={handleDateFromChange}
                          />
                        </div>
                        <div>
                        <label>الي تاريخ</label>
                          <Form.Control
                            type="date"
                            value={dateTo}
                            onChange={handleDateToChange}
                          />
                        </div>
                      </div>
                    )}

                    {selectedOption !== "custom" && (
                      <div className={`${styles.col__date}`}>
                        <div>
                        <label>من تاريخ </label>
                          <Form.Control type="date" value={dateFrom} readOnly />
                        </div>
                        <div>
                        <label>الي تاريخ</label>
                          <Form.Control type="date" value={dateTo} readOnly />
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <div className={`${styles.activities}`} dir="rtl">
                  <div
                    className={`${styles.activities__body} ${styles.transit}`}
                  >
                    <p>  معدل دوران الاصناف  </p>
                    <p>( ITR )</p>
                    <h2>{InventoryData?.inventoryTurnover}</h2>
                  </div>
                  <div className={`${styles.activities__body}`}>
                    <p>  عدد ايام بيع المخزون  </p>
                    <p>( DSI )</p>
                    <h2>
                  {InventoryData?.dsi}
                    </h2>
                  </div>
                  <div className={`${styles.activities__body}`}>
                    <p>  معدل البيع</p>
                    <p>( STR )</p>
                    <h2>{InventoryData?.sellThroughRate} %</h2>
                  </div>
                  <div className={`${styles.activities__body}`}>
                    <p>   معدل تحويل المخزون لمبيعات   </p>
                    <p> ( ISR )</p>
                    <h2>
                    {InventoryData?.inventoryToSalesRatio}
                    </h2>
                  </div>
                </div>
            
                <div className='mt-5'>
                <TableStokeTwo data={data}/>
                </div>
           
              </Col>
            </Row>
         
            
         
          </div>
            :
            <div class="loader">
                                    <div class="justify-content-center jimu-primary-loading"></div>
                                  </div>
          }
                
           
             
        
          </Container>
       
        </div>
      </section>
    </>
  )
}

export default StokeTwo