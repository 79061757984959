import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
const FirstCircle = ({qty,name}) => {
  console.log(qty,name

  )
  const combinedData = qty.map((quantity, index) => ({
    name: name[index],
    y: parseFloat(quantity) // Convert string to float for numeric operations
  }));
    const [state, setState] = useState({
        series:qty,
            options: {
              chart: {
                width: 180,
                type: 'pie',
              },
              labels:name,
              responsive: [{
                breakpoint: 180,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            },
          
          
      });
      const options = {
        chart: {
          type: 'pie'
        },
     
        title: {
          text: ' الجنسيات'
        },
        tooltip: {
          pointFormat: '{point.y:.2f}' // Show actual quantity
        },
       
        plotOptions: {
            pie: {
                size: 200
            },
          series: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: [{
              enabled: true,
              distance: 20
            }, {
              enabled: true,
              distance: -40,
              format: '{point.y} ({point.percentage:.0f}%)',
              style: {
                fontSize: '0.8em',
                textOutline: 'none',
                opacity: 0.7
              },
              filter: {
                operator: '>',
                property: 'percentage',
                value: 10
              }
            }]
          }
        },
        series: [
          {
            name: 'Quantities',
            colorByPoint: true,
            data:combinedData
          }
        ]
      }
  return (
    <>
            <HighchartsReact
        highcharts={Highcharts}
        options={options}
        
      />
    </>
  )
}

export default FirstCircle