import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
const SecondColumn = ({governorate}) => {
 
    const getChartData = () => {
        return governorate
            .sort((a, b) => b.empNo - a.empNo) // Sort from max to min
            .map(q => [q.governorateName, q.empNo]);
    };
    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'المحافظات'
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            type: 'category',
            labels: {
                autoRotation: [-45, -90],
                style: {
                    fontSize: '13px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            }
        },
        legend: {
            enabled: false
        },
        tooltip: {
            pointFormat: ' <b>{point.y} </b>'
        },
        series: [{
            name: 'Population',
            colors: [
                '#9b20d9', '#9215ac', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
                '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
                '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
                '#03c69b',  '#00f194'
            ],
            colorByPoint: true,
            groupPadding: 0,
            data: getChartData(),
            dataLabels: {
                enabled: true,
                rotation: -90,
                color: '#000',
                inside: true,
                verticalAlign: 'top',
                format: '{point.y}', // one decimal
                y: -30, // 10 pixels down from the top
                style: {
                    fontSize: '13px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }
        }]
    };
  return (
    <>
    <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
    </>
  )
}

export default SecondColumn