import { useMemo,useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
const CustomPagination = (props) => {
    const { page, setPage, pageCount } = props;
  
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start' }} dir='rtl'>
        <button
          onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
          disabled={page === 0}
          style={{ marginRight: '8px' }} // Add margin for spacing
        >
          <KeyboardArrowRight style={{ transform: 'rotate(180deg)' }} />
        </button>
        <span>{`Page ${page + 1} of ${pageCount}`}</span>
        <button
          onClick={() => setPage((prev) => Math.min(prev + 1, pageCount - 1))}
          disabled={page === pageCount - 1}
          style={{ marginLeft: '8px' }} // Add margin for spacing
        >
          <KeyboardArrowRight />
        </button>
      </div>
    );
  };
  
const FirstTable = ({data}) => {
    const columns = useMemo(
        () => [
            {
              accessorKey: 'branchName',
              header: '  اسم الفرع ',
     
              
            },
          
            {
                accessorKey: 'totalEmpNo',
                header: '  عدد كل الموظفين العاملين ',
              },
              {
                accessorKey: 'newEmpNo',
                header: '   الموظفين الجدد  ',
       
                
              },
            
              {
                  accessorKey: 'terminateEmpNo',
                  header: '  المستقيلين',
                },
                {
                  accessorKey: 'turnover',
                  header: '  معدل الاستقالات (%)',
                },
          ],
          [],
      );
    
      
      const table = useMaterialReactTable({
        columns,
        data,
        columnResizeDirection: 'rtl',
        enableExpandAll: false,
        muiDetailPanelProps: () => ({
          sx: (theme) => ({
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(255,210,244,0.1)'
                : 'rgba(0,0,0,0.1)',
          }),
        }),
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        muiSearchTextFieldProps: {
          size: 'small',
          variant: 'outlined',
        },
        muiPaginationProps: {
            component: CustomPagination, // Use custom pagination component
            color: 'secondary',
            shape: 'rounded',
            variant: 'outlined',
            rowsPerPageOptions: [10, 20, 50],
          },
        pagination: {
          pageSize: 20, // Default page size
        },
        muiExpandButtonProps: ({ row, table }) => ({
          onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), 
          sx: {
            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s',
          },
        }),
        
        pagination: {
          pageSize: 20// Default page size
        },
      })
      return <MaterialReactTable table={table} />;
}

export default FirstTable