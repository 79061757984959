import { useMemo,useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
const ThirdTable = ({data}) => {
    const columns = useMemo(
        () => [
            {
                accessorKey: 'jobName',
                header: '  اسم الوظيقة ',
       
                
              },
            
              {
                  accessorKey: 'totalEmpNo',
                  header: '  عدد كل الموظفين العاملين ',
                },
                {
                  accessorKey: 'newEmpNo',
                  header: '   الموظفين الجدد  ',
         
                  
                },
              
                {
                    accessorKey: 'terminateEmpNo',
                    header: '  المستقيلين',
                  },
                  {
                    accessorKey: 'turnover',
                    header: '  معدل الاستقالات (%)',
                  },
                /*   {
                    accessorKey: 'turnover',
                    header: '  معدل الاستقالات  ',
                    columnDefType: "display",
                    enableColumnOrdering: 1,
                    filterVariant: 'text',
                    Cell: ({ renderedCellValue, row, cell }) => (
                      <>
            
                        <div>
                                {row.original.turnover?
            <p>{row.original.turnover.toFixed(2)} %</p>
            :
            <p>{row.original.turnover.toFixed(2)} %</p>
                    }
                                </div>
                      </>
                    ),
                  }, */
             
          ],
          [],
      );
    
      
      const table = useMaterialReactTable({
        columns,
        data,
        columnResizeDirection: 'rtl',
        enableExpandAll: false,
        muiDetailPanelProps: () => ({
          sx: (theme) => ({
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(255,210,244,0.1)'
                : 'rgba(0,0,0,0.1)',
          }),
        }),
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        muiSearchTextFieldProps: {
          size: 'small',
          variant: 'outlined',
        },
        muiPaginationProps: {
          color: 'secondary',
          shape: 'rounded',
          variant: 'outlined',
          rowsPerPageOptions: [10, 20, 50], // Options for rows per page
  
         
        },
        pagination: {
          pageSize: 20, // Default page size
        },
        muiExpandButtonProps: ({ row, table }) => ({
          onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), 
          sx: {
            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s',
          },
        }),
        
        pagination: {
          pageSize: 20// Default page size
        },
      })
      return <MaterialReactTable table={table} />;
}

export default ThirdTable